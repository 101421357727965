// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-achievements-js": () => import("./../../../src/pages/achievements.js" /* webpackChunkName: "component---src-pages-achievements-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-media-ru-js": () => import("./../../../src/pages/media.ru.js" /* webpackChunkName: "component---src-pages-media-ru-js" */),
  "component---src-pages-news-2021-08-07-bp-is-closed-ru-js": () => import("./../../../src/pages/news/2021-08-07-bp-is-closed.ru.js" /* webpackChunkName: "component---src-pages-news-2021-08-07-bp-is-closed-ru-js" */),
  "component---src-pages-projects-appmetr-js": () => import("./../../../src/pages/projects/appmetr.js" /* webpackChunkName: "component---src-pages-projects-appmetr-js" */),
  "component---src-pages-projects-bp-js": () => import("./../../../src/pages/projects/bp.js" /* webpackChunkName: "component---src-pages-projects-bp-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */)
}

